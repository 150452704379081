import { FC } from 'react'
import styles from './StoreScreen.module.css'

export const StoreScreen: FC = () => {
  return (
    <div className={styles.screen}>
      {/* TODO: If more screns are required */}
    </div>
  )
}
